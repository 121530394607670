const SHOPIFY_URL = process.env.GATSBY_SHOPIFY_URL;
const SHOPIFY_ACCESS_TOKEN = process.env.GATSBY_SHOPIFY_ACCESS_TOKEN;
const SHOPIFY_API_VERSION = "2023-04";
const SHOPIFY_GRAPHQL_URL = `${SHOPIFY_URL}/api/${SHOPIFY_API_VERSION}/graphql.json`;

const fetchShopifyGraphql = async (query, variables = {}) => {
  const response = await fetch(SHOPIFY_GRAPHQL_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token": SHOPIFY_ACCESS_TOKEN,
    },
    body: JSON.stringify({ query, variables }),
  });
  if (!response.ok)
    throw new Error(
      `Error ${response.status} ${response.statusText} calling ${SHOPIFY_GRAPHQL_URL}`
    );
  const result = await response.json();
  if (result.errors?.length) {
    throw new Error(`Graphql error - ${result.errors.map((item) => item.message).join(" - ")}`);
  }
  const { data } = result;
  return data;
};

export const hasShopify = !!SHOPIFY_URL;
export const getCartUrl = async (cartId) => {
  const cart = await queryCart(cartId);
  const productsVariant = cart?.items.map((product) => {
    const productVariant = product.id.split("/")[product.id.split("/").length - 1];
    const productVariantQuantity = product.quantity;
    return `${productVariant}:${productVariantQuantity}`;
  });

  const cartUrl = !!productsVariant.length
    ? `${SHOPIFY_URL}/cart/${productsVariant}?storefront=true`
    : null;

  return cartUrl || null;
};

export const createCart = async () => {
  const { cartCreate } = await fetchShopifyGraphql(
    `
    mutation CreateCart{
      cartCreate{
        cart {
          id
        }
        userErrors {
          code
          field
          message
        }
      }
    }
  `
  );
  return cartCreate.cart?.id;
};

const normalizeProduct = (shopifyProduct) => {
  const price = shopifyProduct?.node?.price;
  const compareAtPrice = shopifyProduct?.node?.compareAtPrice;
  const compareAtPriceValue = parseFloat(compareAtPrice?.amount);
  const priceValue = parseFloat(price?.amount);
  const onlineStoreUrlId = shopifyProduct?.node?.id.split("/").slice(-1)?.[0];

  return {
    id: shopifyProduct?.node?.id || shopifyProduct.id,
    sku: shopifyProduct?.node.sku,
    name: shopifyProduct.title,
    descriptionHtml: shopifyProduct.descriptionHtml,
    availableForSale: shopifyProduct?.node?.availableForSale,
    msrpPrice:
      compareAtPriceValue && compareAtPrice?.currencyCode
        ? {
            value: compareAtPriceValue,
            currency: compareAtPrice.currencyCode,
          }
        : null, //MSRP
    sellingPrice:
      priceValue && price?.currencyCode
        ? {
            value: priceValue,
            currency: price.currencyCode,
          }
        : null, // SELLING PRICE
    shopLink: `${shopifyProduct.onlineStoreUrl}?variant=${onlineStoreUrlId}`,
    contributoRaee: shopifyProduct?.contributoRaee,
    omnibusPrice: shopifyProduct.node?.omnibusPrice, // OMNIBUS PRICE,
    repairabilityIndex: shopifyProduct?.repairabilityIndex,
    pdfRepairabilityLink: shopifyProduct?.shop?.primaryDomain?.url
      ? `${shopifyProduct.shop.primaryDomain.url}/cdn/shop/files/${shopifyProduct.variants.nodes[0].sku}.pdf`
      : null,
  };
};

const normalizeCartProduct = (cartProduct) => {
  const { price, compareAtPrice } = cartProduct;
  const compareAtPriceValue = parseFloat(compareAtPrice?.amount);
  const priceValue = parseFloat(price?.amount);

  return {
    id: cartProduct.id,
    sku: cartProduct.sku,
    availableForSale: cartProduct.availableForSale,
    msrpPrice:
      compareAtPriceValue && compareAtPrice?.currencyCode
        ? {
            value: compareAtPriceValue,
            currency: compareAtPrice.currencyCode,
          }
        : null,
    sellingPrice:
      priceValue && price?.currencyCode
        ? {
            value: priceValue,
            currency: price.currencyCode,
          }
        : null,
    omnibusPrice: cartProduct?.omnibusPrice?.[0]?.value
      ? {
          value: parseFloat(JSON.parse(cartProduct.omnibusPrice?.[0].value)?.amount),
          currency: JSON.parse(cartProduct.omnibusPrice?.[0].value)?.currency_code,
        }
      : null,
  };
};

const normalizeCart = (cart) => {
  const lines = cart.lines;
  delete cart.lines;
  return {
    ...cart,
    items: lines.nodes?.length
      ? lines.nodes.filter(Boolean)?.map((item) => ({
          nodeId: item.id,
          quantity: item.quantity,
          ...normalizeCartProduct(item.merchandise),
        }))
      : [],
  };
};

export const queryCart = async (cartId) => {
  if (!cartId) return null;
  const pageSize = 20;
  let hasNextPage = true;
  let startCursor = null;
  let resultCart;
  while (hasNextPage) {
    const { cart } = await fetchShopifyGraphql(
      `
      query CartQuery($cartId: ID!, $pageSize: Int!, $startCursor: String){
        cart(id: $cartId) {
          id
          checkoutUrl
          lines(first: $pageSize, after: $startCursor) {
            nodes {
              id
              merchandise {
                ... on ProductVariant {
                  id
                  sku
                  availableForSale
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  price {
                      amount
                      currencyCode
                  }
                  omnibusPrice: metafields(identifiers: { namespace: "omnibus", key: "price" }) {
                    value
                  }
                }
              }
              quantity
            }
            pageInfo {
              hasNextPage
              startCursor
            }
          }
        }
      }
    `,
      { cartId, pageSize, startCursor }
    );
    if (!cart) {
      throw new Error("Carrello anonimo non valido");
    }
  
    hasNextPage = !!cart.lines?.pageInfo?.hasNextPage;
    startCursor = hasNextPage ? cart.lines?.pageInfo?.startCursor : null;
    const prevItems = resultCart?.items || [];
    resultCart = normalizeCart(cart);
    resultCart.items = prevItems.concat(resultCart.items);
  }
  return resultCart;
};

const splitChunks = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_v, i) =>
    arr.slice(i * size, i * size + size)
  );

export const queryProductsDetails = async (productIds) => {
  const pageSize = 8;
  const chunks = splitChunks(productIds, pageSize);
  const products = [];
  for (const chunk of chunks) {
    const res = await fetchShopifyGraphql(
      `query ProductsQuery($first: Int, $query: String){
        products(first: $first, query: $query) {
          nodes {
            id
            title
            availableForSale
            variants(first: 30) {
              nodes {
                sku
                id
                omnibusPrice: metafields(identifiers: { namespace: "omnibus", key: "price" }) {
                  value
                }
                price {
                  amount
                  currencyCode
                }
                compareAtPrice {
                  amount
                  currencyCode
                }
                availableForSale
              }
            }
            descriptionHtml
            onlineStoreUrl
            contributoRaee: metafields(identifiers: { namespace: "tax", key: "eco_tax" }) {
              value
            }
            repairabilityIndex: metafields(identifiers: { namespace: "custom", key: "repairability_index" }) {
              value
            }
          }
          pageInfo {
            hasNextPage
            startCursor
          }
        }
        shop {
          primaryDomain {
              url
          }
        }
    }`,
      {
        query: `nodes:sku:${chunk.join(" OR ")}`,
        first: chunk.length,
      }
    );
    if (res.products?.nodes?.length) {
      products.push(
        ...res.products.nodes
          .map(
            (item) =>
              item?.variants?.nodes?.map((node) => ({ ...item, shop: res.shop, node })) || []
          )
          .flat()
      );
    }
  }
  return products
    .filter((product) => productIds.includes(product.node.sku))
    .map((item) => normalizeProduct(item));
};

export const addProductToCart = async (productId, cartId) => {
  const products = await queryProductsDetails([productId]);
  const merchandiseId = products?.[0]?.id;
  if (merchandiseId) {
    const { cartLinesAdd } = await fetchShopifyGraphql(
      `
        mutation AddToCart($cartId: ID!, $merchandiseId: ID!, $quantity: Int!) {
          cartLinesAdd(cartId: $cartId, lines: [
            {
              quantity: $quantity,
              merchandiseId: $merchandiseId
            }
          ]) {
            cart {
              id
              checkoutUrl
              lines(first: 20) {
                nodes {
                  id
                  merchandise {
                    ... on ProductVariant {
                      id
                      sku
                      availableForSale
                      compareAtPrice {
                        amount
                        currencyCode
                      }
                      price {
                          amount
                          currencyCode
                      }
                    }
                  }
                  quantity
                }
                pageInfo {
                  hasNextPage
                  startCursor
                }
              }
            }
            userErrors {
              code
              field
              message
            }
          }
        }`,
      { cartId, merchandiseId, quantity: 1 }
    );
    const { cart } = cartLinesAdd;
    if (cart.lines?.pageInfo?.hasNextPage) {
      return {
        ...cartLinesAdd,
        cart: await queryCart(cartId),
      };
    } else {
      const result = { ...cartLinesAdd, cart: normalizeCart(cartLinesAdd.cart) };
      console.log("normalized: ", result);
      return result;
    }
  } else {
    throw new Error(`Product ${productId} not found`);
  }
};
